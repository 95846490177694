/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../Cider.ShoppingCart/Assets/TypeScript/purchase-product.ts' />

class PromotedMembership {
    constructor(
        private promotedMembershipContainer: JQuery,
        private addToCartContainer: JQuery) {
    }

    public init(): void {

        let purchaseProduct: PurchaseProduct = new PurchaseProduct(
            this.promotedMembershipContainer.find('button.purchase-membership'),
            this.promotedMembershipContainer.find('.purchase-product-modal'),
            this.promotedMembershipContainer.data('suppress-modal'));
        purchaseProduct.init();

        let promotionChoiceDropdown: JQuery = this.addToCartContainer.find('select[name="promotionChoice"]');
        promotionChoiceDropdown.change(() => {
            this.updateMemberPrice(promotionChoiceDropdown);
        });

        this.updateMemberPrice(promotionChoiceDropdown);
                
        $(document).on('cider.shoppingCart.cartUpdated', () => {
            this.reloadAddToCartLocation();
        });
    }

    private updateMemberPrice(
        promotionChoiceDropdown: JQuery): void {

        var choicesArray = $.makeArray(this.promotedMembershipContainer.data("all-prices"));
        var selectedChoice = $.grep(choicesArray, (choice) => { return choice.ChoiceCode === promotionChoiceDropdown.val(); })[0];

        if (selectedChoice) {
            let selectedOption: JQuery = promotionChoiceDropdown.find('option:selected');

            this.addToCartContainer.find(".member-price").text(selectedChoice.PriceText);
            this.addToCartContainer.find(".member-savings-text small").text(selectedChoice.SavingsDescriptionText.Text);
            this.addToCartContainer.find('.item-price-label').text(selectedOption.data('discount-price-label'));
        }
    }

    private reloadAddToCartLocation(): void {
        let addToCartLocation: JQuery = this.addToCartContainer.parents('.add-to-cart-location');
        if (addToCartLocation.length) {
            addToCartLocation.removeClass('listening');
            $(document).trigger('cider.core.productsLoaded');
        }
    }
}

$(document).on('cider.shoppingCart.addToCartInitialized', (_e: JQueryEventObject, container: any) => {

    let addToCartContainer: JQuery = $(container);
    let promotedMembershipContainer = addToCartContainer.siblings('.add-to-cart-promoted-membership-container');

    if (promotedMembershipContainer.length) {
        let promotedMembership: PromotedMembership = new PromotedMembership(promotedMembershipContainer, addToCartContainer);
        promotedMembership.init();
    }
});